<template>
  <b-form-group>
    <label for="label">
      {{ showLabel ? (label ? label : "Motivo") : " " }}</label
    >
    <multiselect
      v-model="computedValue"
      :options="attendances"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="placeholder"
      label="name"
      track-by="id"
      selectLabel=""
      selectedLabel=""
      deselectLabel=""
      @select="callback"
    >
      <span slot="noResult">Nada encontrado</span>
    </multiselect>
  </b-form-group>
</template>

<script>
export default {
  props: [
    "name",
    "label",
    "placeholder",
    "value",
    "callback",
    "showLabel",
  ],
  data() {
    return {
      options: "",
      attendance: null,
      attendances: [
        { id: "1", value: "presence", name: "Presença" },
        { id: "2", value: "regular_leave", name: "Saída Regular" },
        { id: "3", value: "antecipated", name: "Saída Antecipada" },
        { id: "4", value: "late", name: "Chegada Tardia" },
        { id: "5", value: "absence", name: "Falta" },
      ],
    };
  },
  computed: {
    computedValue() {
      if (typeof this.value === "number") {
        return this.attendances.find((c) => c.id == this.value);
      } else {
        return this.value;
      }
    },
  },
};
</script>
