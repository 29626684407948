import http from "../http-rda";

class DataService {
  getAll(params) {
    return http.get("/attendances?include=students", { params });
  }

  get(id) {
    return http.get(`/attendances/${id}`);
  }

  getStudent(id) {
    return http.get(`/attendances/${id}`);
  }

  getStudentAttendances(id) {
    return http.get(`/students/${id}/attendances`);
  }

  create(data) {
    return http.post("/attendances", data);
  }

  update(id, data) {
    return http.patch(`/attendances/${id}`, data);
  }

  uploadCSV(file) {
    let formData = new FormData();

    formData.append("file", file);

    return http.post("attendances/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
    });
  }

  delete(id) {
    return http.delete(`/attendances/${id}`);
  }

  findByTitle(name) {
    return http.get(`/attendances?search=${name}`);
  }

}

export default new DataService();
