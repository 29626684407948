<template>
  <b-container class="container-rda">
    <b-row>
      <b-col>
        <b-breadcrumb :items="breadcrumb"></b-breadcrumb>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col md="4">
        <h2 class="purple bold">Faltas e presenças</h2>
      </b-col>
      <b-col class="text-right">
        <b-button @click="openModalAttClass" class="purple primary"
          >Marcar presença na turma</b-button
        >
      </b-col>
      <b-col class="text-right">
        <b-button
          class="large blue"
          :to="{
            name: redirectToCorrectUrl('registrar-faltas-presencas'),
          }"
          >Adicionar novo</b-button
        >
      </b-col>
      <b-col class="text-right import-csv">
        <b-button class="large blue" @click="generateReport"
          >Exportar Relatório</b-button
        >
      </b-col>
      <b-col class="text-right import-csv">
        <b-button class="large blue" @click="generateReportAudit"
          >Exportar Relatório Auditoria</b-button
        >
      </b-col>
    </b-row>

    <b-row>
      <b-col md="2">
        <b-form-group label="Turmas">
          <multiselect
            v-model="classroom"
            :options="classrooms"
            :multiple="true"
            placeholder="Selecione"
            label="designation"
            track-by="id"
            selectLabel=""
            @select="callbackClassrooms"
            @remove="callbackRemoveClassrooms"
          >
            <span slot="noResult">Nada encontrado</span>
          </multiselect>
        </b-form-group>
        <span class="clear-field" @click="clearFields">Limpar filtros</span>
      </b-col>

      <b-col md="2">
        <b-form-group class="calendar">
          <label>De</label>
          <Datepicker
            v-model="startDateFilter"
            placeholder="Selecione"
            format="dd/MM/yyyy"
            :use-utc="true"
            :language="languages[language]"
            @selected="callbackStartDateFilter"
          >
          </Datepicker>
        </b-form-group>
      </b-col>
      <b-col md="2">
        <b-form-group class="calendar">
          <label>Até</label>
          <Datepicker
            v-model="endDateFilter"
            placeholder="Selecione"
            format="dd/MM/yyyy"
            :use-utc="true"
            :language="languages[language]"
            @selected="callbackEndDateFilter"
          >
          </Datepicker>
        </b-form-group>
      </b-col>

      <b-col md="2">
        <SelectAttendances
          v-model="attendance"
          showLabel="true"
          placeholder="Selecione"
          :callback="callbackAttendances"
        />
      </b-col>

      <b-col md="2">
        <b-form-group label="Local do registro">
          <multiselect
            v-model="registerPlace"
            :options="qrcodeUsers"
            :multiple="false"
            placeholder="Selecione"
            label="name"
            track-by="id"
            selectLabel=""
            @select="callbackAttendancesPlaces"
          >
            <span slot="noResult">Nada encontrado</span>
          </multiselect>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group label="Buscar">
          <b-input-group-prepend>
            <b-form-input
              type="search"
              placeholder="Digite aqui"
              v-model="searchTitle"
              v-on:keyup.enter="
                page = 1;
                getAttendanceList();
              "
            ></b-form-input>
            <b-input-group-text
              class="clear-fields"
              v-if="searchTitle != '' || this.attendance != ''"
              v-b-tooltip.hover
              title="Limpar Pesquisa"
            >
              <b-icon icon="x" @click="clearFields" />
            </b-input-group-text>
          </b-input-group-prepend>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row style="margin-top: 20px">
      <b-col>
        <b-container fluid>
          <b-row
            class="header-list-teacher"
            v-if="attendances.length > 0 && isRequestSend"
          >
            <b-col md="2">
              <p class="bold">Aluno</p>
            </b-col>
            <b-col>
              <p class="bold">Matricula</p>
            </b-col>
            <b-col md="2" style="padding-left: 5.5%">
              <p class="bold">Turma</p>
            </b-col>
            <b-col>
              <p class="bold">Evento</p>
            </b-col>
            <b-col>
              <p class="bold text-align">Tipo</p>
            </b-col>
            <b-col class="text-align" md="2">
              <p class="bold">Data e Hora</p>
            </b-col>
            <b-col class="text-align" md="1">
              <p class="bold">Local/Usuário</p>
            </b-col>
            <b-col class="text-align" md="1">
              <p class="bold">Ações</p>
            </b-col>
          </b-row>
        </b-container>

        <b-container fluid>
          <b-row
            align-v="center"
            class="list-teacher"
            v-for="attendance in attendances"
            v-bind:key="attendance.id"
          >
            <b-col md="2">
              <router-link
                :to="{
                  name: 'perfil-aluno',
                  params: {
                    id: attendance.Student.id,
                  },
                }"
                target="_blank"
              >
                <p v-if="attendance.Student.User">
                  {{ attendance.Student.User.name }}
                </p>
              </router-link>
            </b-col>
            <b-col>
              <p>{{ attendance.student_id }}</p>
            </b-col>
            <b-col
              md="2"
              class="mx-auto d-flex justify-content-center align-items-center"
            >
              <router-link
                :to="{
                  name: 'perfil-turma',
                  params: {
                    id: attendance.Student.classroom_id,
                  },
                }"
                target="_blank"
              >
                <p>
                  <span class="classroom">
                    {{ getClassroomName(attendance.Student.classroom_id) }}
                  </span>
                </p>
              </router-link>
            </b-col>
            <b-col>
              <p>
                <span
                  class="attendance"
                  :class="{
                    absence: attendance.type === 'absence' ? 'absence' : '',
                    presence: attendance.type === 'presence' ? 'presence' : '',
                    late: attendance.type === 'late' ? 'late' : '',
                    antecipated:
                      attendance.type === 'antecipated' ? 'antecipated' : '',
                    regular_leave:
                      attendance.type === 'regular_leave'
                        ? 'regular_leave'
                        : '',
                  }"
                >
                  {{
                    attendance.type === "absence"
                      ? "Falta"
                      : "" || attendance.type === "presence"
                      ? "Presença"
                      : "" || attendance.type === "late"
                      ? "Chegada Tardia"
                      : "" || attendance.type === "antecipated"
                      ? "Saída Antecipada"
                      : "" || attendance.type === "regular_leave"
                      ? "Saída Regular"
                      : ""
                  }}
                </span>
              </p>
            </b-col>
            <b-col>
              <p style="font-size: 17px">
                {{
                  attendance.period === "regular"
                    ? "Horário regular"
                    : "Contraturno"
                }}
              </p>
            </b-col>
            <b-col md="2">
              <p class="text-align">
                {{ attendance.date }}
              </p>
            </b-col>

            <b-col md="1">
              <p class="text-align">
                {{
                  attendance.origin === "job"
                    ? "Automático"
                    : attendance.token_user
                    ? attendance.token_user.name
                    : "Usuário inválido"
                }}
              </p>
            </b-col>

            <b-col md="1" class="action-btns">
              <router-link
                :to="{
                  name: redirectToCorrectUrl('visualizar-faltas-presencas'),
                  params: {
                    id: attendance.id,
                  },
                }"
              >
                <ViewIcon />
              </router-link>

              <router-link
                :to="{
                  name: redirectToCorrectUrl('editar-faltas-presencas'),
                  params: {
                    id: attendance.id,
                  },
                }"
              >
                <EditIcon />
              </router-link>

              <a v-on:click.prevent="exclude(attendance.id)">
                <DeleteIcon />
              </a>
            </b-col>
          </b-row>
          <b-row
            align-v="center"
            class="list-teacher"
            v-if="attendances.length <= 0 && isRequestSend"
          >
            <b-col>Nenhum resultado encontrado</b-col>
          </b-row>
          <b-row align-v="center" class="list-teacher" v-if="!isRequestSend">
            <b-col>Carregando</b-col>
          </b-row>
          <b-row
            class="align-center"
            v-else-if="count > perPage && isRequestSend"
          >
            <b-pagination
              v-model="page"
              :per-page="perPage"
              :total-rows="count"
              @change="changePage"
              aria-controls="teachersList"
              last-text
              first-text
            >
              <template #prev-text>
                <LeftArrowIcon class="left" />
              </template>
              <template #next-text>
                <LeftArrowIcon />
              </template>
            </b-pagination>
          </b-row>
        </b-container>
      </b-col>
    </b-row>

    <b-modal
      id="modal-att-class"
      title="Marcar presença para turma"
      size="xl"
      hide-footer
    >
      <b-row>
        <b-col md="2">
          <b-form-group label="Turmas">
            <multiselect
              v-model="classroomModal"
              :options="classrooms"
              :multiple="true"
              placeholder="Selecione"
              label="designation"
              track-by="id"
              selectLabel=""
              @select="callbackClassroomsModal"
              @remove="callbackRemoveClassroomsModal"
            >
              <span slot="noResult">Nada encontrado</span>
            </multiselect>
          </b-form-group>
          <span class="clear-field" @click="clearFieldsModal"
            >Limpar filtros</span
          >
        </b-col>
        <b-col md="2">
          <b-form-group class="calendar">
            <label>Data da presença</label>
            <Datepicker
              v-model="attendanceDate"
              placeholder="Selecione"
              format="dd/MM/yyyy"
              :use-utc="true"
              :language="languages[language]"
            >
            </Datepicker>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <StandardInput
            v-model="timeModal"
            type="time"
            name="designation"
            label="Hora"
          >
          </StandardInput>
        </b-col>
        <b-col md="2">
          <SelectAttendances
            v-model="geralTypeModal"
            placeholder="Selecione"
            :showLabel="true"
            label="Evento"
            :disabled="studentsModal.length <= 0"
            :callback="callbackGeraltEventModal"
          />
        </b-col>
        <b-col md="2">
          <SelectSimple
            label="Tipo"
            v-model="generalPeriodModel"
            :disabled="studentsModal.length <= 0"
            :options="[
              { id: 'regular', label: 'Horário regular' },
              { id: 'after_hour', label: 'Contraturno' },
            ]"
            :callback="callbackGeraltTypeModal"
          />
        </b-col>
        <b-col md="1">
          <b-form-group class="label-add" :label="'&nbsp;'">
            <b-button
              :disabled="
                !attendanceDate ||
                !timeModal ||
                !geralTypeModal ||
                !generalPeriodModel ||
                selectedClassroomsModal.length <= 0 ||
                studentsModal.length <= 0
              "
              @click="submitAllStudents"
              class="blue"
              >Registrar</b-button
            >
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col style="margin-top: 15px" md="12">
          <h2>Alunos</h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-container fluid>
            <b-row class="header-list-teacher" v-if="studentsModal.length > 0">
              <b-col>
                <p class="bold">Matrícula</p>
              </b-col>
              <b-col md="3">
                <p class="bold">Nome</p>
              </b-col>
              <b-col>
                <p class="bold">Tipo</p>
              </b-col>
              <b-col>
                <p class="bold">Período</p>
              </b-col>
              <b-col>
                <p class="bold"></p>
              </b-col>
            </b-row>
          </b-container>
          <b-container fluid>
            <b-row
              align-v="center"
              class="list-teacher"
              v-for="(student, index) in studentsModal"
              v-bind:key="index"
            >
              <b-col>
                <p>{{ student.student_id }}</p>
              </b-col>
              <b-col md="4">
                <p>
                  {{ student ? student.name : "Nome não cadastrado" }}
                </p>
              </b-col>
              <b-col>
                <SelectAttendances
                  v-model="student.type"
                  placeholder="Selecione"
                  :callback="(value) => callbackAttendancesModal(value, index)"
                />
              </b-col>
              <b-col>
                <SelectSimple
                  v-model="student.period"
                  :options="[
                    { id: 'regular', label: 'Horário regular' },
                    { id: 'after_hour', label: 'Contraturno' },
                  ]"
                  :callback="callbackAttendances"
                />
              </b-col>
              <b-col>
                <a
                  style="display: flex; justify-content: center"
                  v-on:click.prevent="studentsModal.splice(index, 1)"
                >
                  <DeleteIcon />
                </a>
              </b-col>
            </b-row>
          </b-container>
        </b-col>
      </b-row>
      <b-row v-if="!studentsModal.length > 0">
        <b-col md="12">
          <p v-if="studentsModal.length <= 0">Nenhum resultado encontrado</p>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import DataService from "@/services/attendances.service";
import DataServiceClassrooms from "@/services/classrooms.service";
import SelectAttendances from "@/components/inputs/SelectAttendances.vue";
import LeftArrowIcon from "@/components/svg/LeftArrowIcon.vue";
import ViewIcon from "@/components/svg/ViewIcon.vue";
import EditIcon from "@/components/svg/EditIcon.vue";
import DeleteIcon from "@/components/svg/DeleteIcon.vue";
import moment from "moment-timezone";
import httpRda from "../../../http-rda";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import SelectSimple from "@/components/inputs/SelectSimple.vue";
import StandardInput from "@/components/inputs/StandardInput.vue";
import { mapGetters } from "vuex";

export default {
  name: "faltas-presencas",
  components: {
    SelectAttendances,
    LeftArrowIcon,
    ViewIcon,
    EditIcon,
    DeleteIcon,
    Datepicker,
    SelectSimple,
    StandardInput,
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getAuthData",
    }),
  },

  data() {
    return {
      attendances: [],
      classrooms: [],
      registerPlace: null,
      filecsv: null,
      qrcodeUsers: [],
      paramsReport: {},

      descOrder: false,
      isRequestSend: false,

      classroom: [],
      selectedClassrooms: [],
      classroomModal: [],
      selectedClassroomsModal: [],
      attendance: null,
      searchTitle: "",

      page: this.$route.params.page || 1,
      count: 0,
      startDateFilter: null,
      endDateFilter: null,
      perPage: 20,

      studentsModal: [],
      attendanceDate: null,
      timeModal: moment().format("HH:mm").toString(),
      geralTypeModal: {
        id: "presence",
        value: "presence",
        name: "Presença",
      },
      generalPeriodModel: {
        id: "regular",
        label: "Horário regular",
      },

      breadcrumb: [
        {
          text: "Secretaria",
          href: "/secretaria",
        },
        {
          text: "Faltas e presenças",
          active: true,
        },
      ],

      language: "ptBR",
      languages: lang,
    };
  },

  filters: {
    date(date) {
      try {
        return moment(date).format("DD/MM/YYYY");
      } catch (error) {
        return date;
      }
    },
  },

  methods: {
    callbackStartDateFilter(date) {
      const currentTzOffset = Math.abs(moment().utcOffset() / 60);
      this.startDateFilter = moment(date)
        .add("day", currentTzOffset > 0 ? 1 : 0)
        .format("YYYY-MM-DD")
        .toString();
      this.page = 1;

      this.getAttendanceList();
    },

    callbackEndDateFilter(date) {
      const currentTzOffset = Math.abs(moment().utcOffset() / 60);
      this.endDateFilter = moment(date)
        .add("day", currentTzOffset > 0 ? 1 : 0)
        .format("YYYY-MM-DD")
        .toString();
      this.page = 1;

      this.getAttendanceList();
    },

    openModalAttClass() {
      this.$bvModal.show("modal-att-class");
    },

    getRequestParams(
      searchTitle,
      page,
      perPage,
      attendance,
      classroom,
      startDate,
      endDate,
      registerPlace
    ) {
      let params = {};

      if (startDate) {
        params["startDate"] = moment(startDate)
          .add("hours", 4)
          .format("YYYY-MM-DD");
      }

      if (endDate) {
        params["endDate"] = moment(endDate)
          .add("hours", 4)
          .format("YYYY-MM-DD");
      }

      if (searchTitle) {
        params["name"] = searchTitle;
      }

      if (page) {
        params["page"] = page;
      }

      if (perPage) {
        params["limit"] = perPage;
      }

      if (attendance) {
        params["type"] = attendance.value;
      }

      if (classroom) {
        params["students_classroom_id"] = classroom;
      }

      if (registerPlace) {
        params["register_place"] = registerPlace;
      }

      return params;
    },

    getAttendanceList() {
      const params = this.getRequestParams(
        this.searchTitle,
        this.page,
        this.perPage,
        this.attendance,
        this.selectedClassrooms,
        this.startDateFilter,
        this.endDateFilter,
        Number.isInteger(this.registerPlace)
          ? this.registerPlace
          : this.registerPlace && this.registerPlace.id
          ? this.registerPlace.id
          : null
      );

      this.paramsReport = {
        classroom_id: this.selectedClassrooms[0]
          ? this.selectedClassrooms[0]
          : null,
        date_start: this.startDateFilter ? this.startDateFilter : null,
        date_end: this.endDateFilter ? this.endDateFilter : null,
      };

      DataService.getAll(params)
        .then((response) => {
          this.attendances = response.data.attendances;
          this.count = response.data.count;
          this.isRequestSend = true;

          this.attendances.map((attendance) => {
            if (attendance.type === "absence") {
              attendance.date = moment(
                attendance.date,
                "DD/MM/YYYY HH:SS"
              ).format("DD/MM/YYYY");
            }

            return attendance;
          });
        })
        .catch((error) => {
          return error.data;
        });
    },

    getClassrooms() {
      httpRda.get("/config/calendars").then((response) => {
        const currentCalendar = response.data.calendar;

        DataServiceClassrooms.getAll({
          calendar_id: currentCalendar.id,
        })
          .then((response) => {
            this.classrooms = response.data.classrooms;
            if (this.classrooms.length) {
              this.classrooms = this.classrooms.map((classroom) => {
                return {
                  id: classroom.id,
                  designation: classroom.designation,
                  calendar_id: classroom.calendar_id,
                };
              });
            }
          })
          .catch((error) => {
            return error.data;
          });
      });
    },

    exclude(id) {
      this.$swal
        .fire({
          title: "Tem certeza?",
          text: "Você não poderá reverter isso!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#68c5b7",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim, excluir!",
          cancelButtonText: "Cancelar",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              await DataService.delete(id);
              this.$swal
                .fire("Excluído!", "Registro foi excluído.", "success")
                .then((response) => {
                  this.getAttendanceList();
                  return response.data;
                });
            } catch (error) {
              this.$swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possivel excluir!",
                footer: "Por favor, tente novamente mais tarde.",
              });

              return error.response;
            }
          }
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Não foi possivel excluir!",
            footer: "Por favor, tente novamente mais tarde.",
          });

          return error.response;
        });
    },

    orderName() {
      this.descOrder = !this.descOrder;
    },

    callbackClassrooms({ id }) {
      this.selectedClassrooms.push(id);
      this.page = 1;
      this.getAttendanceList();
    },

    callbackRemoveClassrooms({ id }) {
      this.selectedClassrooms = this.selectedClassrooms.filter(
        (classroom) => classroom !== id
      );
      this.page = 1;
      this.getAttendanceList();
    },

    async submitAllStudents() {
      let currentTzOffset = moment().utcOffset();
      currentTzOffset = Math.abs(currentTzOffset / 60);

      let customDate = this.attendanceDate;

      customDate = moment(this.attendanceDate)
        .add(currentTzOffset > 0 ? 1 : 0, "day")
        .format("YYYY-MM-DD");

      this.attendanceDate = moment
        .tz(
          customDate + " " + this.timeModal,
          "YYYY-MM-DD HH:mm",
          "America/Sao_Paulo"
        )
        .utc();

      this.studentsModal = this.studentsModal.map((student) => {
        return {
          ...student,
          type: student.type.value,
          period: student.period.id,
          date: this.attendanceDate,
        };
      });

      httpRda
        .post("/attendances/students", this.studentsModal)
        .then(() => {
          this.$bvModal.hide("modal-att-class");
          this.clearFieldsModal();
          this.getAttendanceList();
          this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
          this.$swal.fire({
            icon: "success",
            title: "Presença marcada com sucesso!",
          });
        })
        .catch((error) => {
          this.$swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.error,
            footer: "Por favor, tente novamente mais tarde.",
          });
        });
    },

    getModalData() {
      this.studentsModal = httpRda
        .get(
          `/students?include=cycle&search=&classroom=${this.selectedClassroomsModal.join()}&only_active=true`
        )
        .then((response) => {
          this.studentsModal = response.data.students;
          this.studentsModal = this.studentsModal.map((student) => {
            return {
              student_id: student.id,
              name: student.User.name,
              classroom_id: student.classroom_id ? student.classroom_id : null,
              calendar_id:
                this.classrooms.find(
                  (classroom) => classroom.id === student.classroom_id
                ).calendar_id || null,
              type: {
                id: "presence",
                value: "presence",
                name: "Presença",
              },
              period: {
                id: "regular",
                label: "Horário regular",
              },
            };
          });
        });
    },

    callbackRemoveClassroomsModal({ id }) {
      this.selectedClassroomsModal = this.selectedClassroomsModal.filter(
        (classroom) => classroom !== id
      );
      this.studentsModal = [];
      this.getModalData();
    },

    callbackClassroomsModal(element) {
      this.selectedClassroomsModal.push(element.id);
      this.getModalData();
    },

    callbackAttendances(id) {
      this.attendance = id !== -1 ? id : null;
      this.page = 1;
      this.getAttendanceList();
    },

    callbackAttendancesPlaces({ id }) {
      this.registerPlace = id !== -1 ? id : null;
      this.page = 1;
      this.getAttendanceList();
    },

    callbackAttendancesModal(value, index) {
      this.studentsModal[index].type = value;
    },

    callbackGeraltEventModal(value) {
      this.geralTypeModal = value;

      this.studentsModal = this.studentsModal.map((student) => {
        return {
          ...student,
          type: value,
        };
      });
    },

    callbackGeraltTypeModal(value) {
      this.generalPeriodModel = value;

      this.studentsModal = this.studentsModal.map((student) => {
        return {
          ...student,
          period: value,
        };
      });
    },

    changePage(page) {
      this.page = page;
      this.getAttendanceList();
      window.scrollTo({ top: 0, behavior: "smooth" });
    },

    clearFields() {
      this.searchTitle = "";
      this.attendance = null;
      this.classroom = [];
      this.selectedClassrooms = [];
      this.page = 1;
      this.registerPlace = null;
      this.startDateFilter = null;
      this.endDateFilter = null;

      this.getAttendanceList({
        page: this.page,
      });
    },

    clearFieldsModal() {
      this.classroomModal = [];
      this.selectedClassroomsModal = [];
      this.studentsModal = [];
      this.attendanceDate = "";
      this.timeModal = moment().format("HH:mm").toString();
      this.geralTypeModal = {
        id: "presence",
        value: "presence",
        name: "Presença",
      };
      this.generalPeriodModel = {
        id: "regular",
        label: "Horário regular",
      };
    },

    getClassroomName(id) {
      const classrooms = this.classrooms.find((c) => c.id === id);
      return classrooms ? classrooms.designation : "-";
    },

    validationForReport() {
      if (this.selectedClassrooms && this.selectedClassrooms.length !== 1) {
        const msg =
          this.selectedClassrooms.length === 0
            ? "Selecione uma turma para exportar o relatório!"
            : "Selecione apenas uma turma para exportar o relatório!";
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: msg,
          footer: "",
        });
        return false;
      }
      return true;
    },

    async generateReport() {
      try {
        if (this.validationForReport()) {
          const ts = moment().valueOf();
          const params = btoa(JSON.stringify(this.paramsReport));
          httpRda
            .get(
              "/attendances/data-report/token?ts=" + ts + "&params=" + params
            )
            .then((response) => {
              const token = response.data.token;

              const link = document.createElement("a");
              link.target = "_blank";
              link.href = `${process.env.VUE_APP_URL}attendances/data-report?token=${token}&ts=${ts}&params=${params}`;
              link.click();
            });

          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: `Relatório exportado com sucesso!`,
          });

          return true;
        }
      } catch (error) {
        this.loadText = "Exportar";
        this.subjectsSelected = [];

        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel exportar o relatório!",
          footer: "Por favor, tente novamente mais tarde.",
        });

        return error;
      }
    },

    async generateReportAudit() {
      try {
        if (this.validationForReport()) {
          const ts = moment().valueOf();
          const params = btoa(JSON.stringify(this.paramsReport));

          await httpRda
            .get(
              "/attendances/data-report/token?ts=" + ts + "&params=" + params
            )
            .then((response) => {
              const token = response.data.token;

              const link = document.createElement("a");
              link.target = "_blank";
              link.href = `${process.env.VUE_APP_URL}attendances/data-report-for-audit?token=${token}&ts=${ts}&params=${params}`;
              link.click();
            });

          const Toast = this.$swal.mixin({
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: `Relatório exportado com sucesso!`,
          });

          return true;
        }
      } catch (error) {
        this.loadText = "Exportar";
        this.subjectsSelected = [];

        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel exportar o relatório!",
          footer: "Por favor, tente novamente mais tarde.",
        });

        return error;
      }
    },

    upload() {
      //this.currentFile = this.filecsv
      DataService.uploadCSV(this.filecsv)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          this.currentFile = undefined;
          return error.data;
        });

      this.filecsv = undefined;
    },

    submitFile() {
      const [file] = this.file;
      const bodyFormData = new FormData();

      bodyFormData.append("file", file);

      const Toast = this.$swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", this.$swal.stopTimer);
          toast.addEventListener("mouseleave", this.$swal.resumeTimer);
        },
      });
      Toast.fire({
        title: "Carregando, por favor... Aguarde",
      });

      try {
        DataService.uploadCSV({
          file: bodyFormData,
        });
        this.getAttendanceList();
        this.$swal.mixin({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 5000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        Toast.fire({
          icon: "success",
          title: "Planilha enviada com sucesso!",
        });
      } catch (error) {
        this.$swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Não foi possivel importar!",
          footer: "Por favor, tente novamente mais tarde.",
        });
      }
    },

    getQrCodeUsers() {
      httpRda.get("/collaborator/qrcode-users").then((response) => {
        this.qrcodeUsers = response.data.users;
        this.qrcodeUsers.unshift({
          id: -1,
          name: "Todos",
        });
      });
    },

    redirectToCorrectUrl(url) {
      if (
        this.currentUser.role &&
        this.currentUser.role.includes("attendance_manager")
      ) {
        return "registro-" + url;
      } else {
        return url;
      }
    },
  },

  created() {
    this.getQrCodeUsers();
    this.getClassrooms();

    if (this.$route.params.page)
      this.getAttendanceList({
        page: this.$route.params.page,
        limit: this.perPage,
        classroom_id: this.classroom,
      });
    else if (!this.$route.params.page) {
      this.getAttendanceList();
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/variables.scss";

.label-add {
  text-indent: -999rem;
  font-size: 0;
}
.home {
  height: 100%;
}

.text-align {
  text-align: center;
}

.action-btns {
  display: flex;
  justify-content: space-between;
}
.tags {
  background-color: $blue;
  border-radius: 5px;
  color: $white;
  font-weight: $bold;
  line-height: 28px;
  margin: 3px 12px 3px 0;
  max-width: 58px;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.classroom {
  border-radius: 5px;
  display: block;
  color: $white;
  font-weight: 700;
  line-height: 28px;
  min-width: 150px !important;
  text-align: center;
  background-color: $blue;
  .span {
    color: $white;
  }
}

.attendance {
  border-radius: 5px;
  display: block;
  color: $white;
  font-weight: 700;
  line-height: 28px;
  min-width: 155px;
  text-align: center;
  &.late {
    background-color: $orange;
  }
  &.absence {
    background-color: $red;
  }
  &.presence {
    background-color: $green2;
  }
  &.antecipated {
    background-color: $purple;
  }
  &.regular_leave {
    background-color: $yellow;
  }
}

.import-csv {
  .form-control-file::before {
    content: "Exportar Relatório";
  }
}
</style>
